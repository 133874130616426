import React from 'react'
import { animated, config, useSpring } from 'react-spring'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { transparentize } from 'polished'
import Layout, { MyPBox } from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'
import { ChildImageSharp } from '../types'

const cfg = require('../../config')

interface QueryResult {
  imageCornwall: ChildImageSharp
  imageDESY: ChildImageSharp
  imagePetriKirche: ChildImageSharp
  imageSeattle: ChildImageSharp
  imageIceland: ChildImageSharp
  imageTrainStation: ChildImageSharp
}

const Content = styled(AnimatedBox)<{ bg: string }>`
  background-color: ${props => transparentize(0.9, props.bg)};

  .photoCaption {
    font-size: 0.8rem;
    text-align: center;
    font-style: italic;
  }
`

const AreaTwoCol = styled(animated.div)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-template-areas: 'box-photoL box-photoR';

  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    grid-template-columns: 1fr;

    grid-template-areas:
      'box-photoL';
      'box-photoR';
  }
`

const BoxText = styled(MyPBox)`
  grid-area: box-text;
`
const BoxPhoto = styled(MyPBox)`
  grid-area: box-photo;
`
const BoxPhotoL = styled(MyPBox)`
  grid-area: box-photoL;
`
const BoxPhotoR = styled(MyPBox)`
  grid-area: box-photoR;
`

const Photos = () => {
  const data: QueryResult = useStaticQuery(query)

  const titleAnimation = useSpring({ config: config.slow, delay: 300, from: { opacity: 0 }, to: { opacity: 1 } })
  const contentAnimation = useSpring({ config: config.slow, delay: 600, from: { opacity: 0 }, to: { opacity: 1 } })
  const imagesAnimation = useSpring({ config: config.slow, delay: 800, from: { opacity: 0 }, to: { opacity: 1 } })

  return (
    <Layout>
      <SEO title={`Photos${cfg.siteTitleSuffix}`} desc="Photos page" />
      <AnimatedBox style={titleAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8]}>
        <h1>Photos</h1>
      </AnimatedBox>
      <Content style={contentAnimation} bg={cfg.backgroundColor} py={8}>
        <MyPBox>
          Welcome to my photo page! Here you will find a few snapshots from my vacations, conference visits, etc.
        </MyPBox>
        <MyPBox px="10vw" style={imagesAnimation}>
          <Img
            alt=""
            key={data.imageTrainStation.childImageSharp.fluid.src}
            fluid={data.imageTrainStation.childImageSharp.fluid}
          />
          <p className="photoCaption">Sunrise on a train station in Mülheim an der Ruhr, Germany (2019)</p>
        </MyPBox>
        <MyPBox px="10vw" style={imagesAnimation}>
          <Img
            alt=""
            key={data.imageIceland.childImageSharp.fluid.src}
            fluid={data.imageIceland.childImageSharp.fluid}
          />
          <p className="photoCaption">Snow and sunrise in Iceland (2017)</p>
        </MyPBox>
        <MyPBox px="10vw" style={imagesAnimation}>
          <Img
            alt="Petrikirche in Lübeck, Germany (2018)"
            key={data.imagePetriKirche.childImageSharp.fluid.src}
            fluid={data.imagePetriKirche.childImageSharp.fluid}
          />
          <p className="photoCaption">Petrikirche in Lübeck, Germany (2018)</p>
        </MyPBox>
        <MyPBox px="25vw" style={imagesAnimation}>
          <Img
            alt="Selfie with DESY, the German Electron Synchrotron, while visiting ICSA 2019 in Hamburg, Germany"
            key={data.imageDESY.childImageSharp.fluid.src}
            fluid={data.imageDESY.childImageSharp.fluid}
          />
          <p className="photoCaption">
            Selfie with <a href="https://en.wikipedia.org/wiki/DESY">DESY</a>, the German Electron Synchrotron, while
            visiting ICSA 2019 in Hamburg, Germany
          </p>
        </MyPBox>
        <MyPBox px="10vw" style={imagesAnimation}>
          <Img
            alt="Seattle Space Needle while visiting ICSA 2018"
            key={data.imageSeattle.childImageSharp.fluid.src}
            fluid={data.imageSeattle.childImageSharp.fluid}
          />
          <p className="photoCaption">Seattle Space Needle while visiting ICSA 2018</p>
        </MyPBox>
        <MyPBox style={imagesAnimation}>
          <Img
            alt="Climbing 'Brown Willy' – the highest hill in Cornwall, England (2018)"
            key={data.imageCornwall.childImageSharp.fluid.src}
            fluid={data.imageCornwall.childImageSharp.fluid}
          />
          <p className="photoCaption">Climbing 'Brown Willy' – the highest hill in Cornwall, England (2018)</p>
        </MyPBox>
        {/*<AreaTwoCol>
          <BoxPhotoL style={imagesAnimation}>
            <Img
              alt="TODO"
              key={data.imagePetriKirche.childImageSharp.fluid.src}
              fluid={data.imagePetriKirche.childImageSharp.fluid}
            />
            <p className="photoCaption">Petrikirche in Lübeck</p>
          </BoxPhotoL>
          <BoxPhotoR style={imagesAnimation}>
            <Img
              alt="TODO"
              key={data.imageDESY.childImageSharp.fluid.src}
              fluid={data.imageDESY.childImageSharp.fluid}
            />
            <p className="photoCaption">
              Selfie with <a href="https://en.wikipedia.org/wiki/DESY">DESY</a>, the German Electron Synchrotron, in
              Hamburg while visiting ICSA 2019
            </p>
          </BoxPhotoR>
        </AreaTwoCol>*/}
      </Content>
    </Layout>
  )
}

export default Photos

const query = graphql`
  query PhotosQuery {
    imageCornwall: file(sourceInstanceName: { eq: "images" }, name: { eq: "photo-cornwall" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    imageDESY: file(sourceInstanceName: { eq: "images" }, name: { eq: "photo-flo-at-desy" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    imagePetriKirche: file(sourceInstanceName: { eq: "images" }, name: { eq: "photo-petri-kirche-luebeck" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    imageSeattle: file(sourceInstanceName: { eq: "images" }, name: { eq: "photo-seattle-space-needle" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    imageIceland: file(sourceInstanceName: { eq: "images" }, name: { eq: "photo-sunrise-iceland" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    imageTrainStation: file(sourceInstanceName: { eq: "images" }, name: { eq: "photo-train-station-muelheim" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
